<app-navbar-style-three></app-navbar-style-three>

<app-homeone-banner></app-homeone-banner>

<!-- <section class="products-area ptb-100 bg-f5f5f5">
    <app-upcoming-products></app-upcoming-products>
</section> -->

<app-facility-style-one></app-facility-style-one>

<app-homeone-reasons></app-homeone-reasons>

<app-homeone-reviews></app-homeone-reviews>

<app-homeone-faq></app-homeone-faq>

<!--<app-homeone-categories></app-homeone-categories>-->

<!-- <section class="products-area pb-100">
    <app-trending-products></app-trending-products>
</section> -->



<!-- <section class="products-area ptb-100">
    <app-popular-products></app-popular-products>
</section> -->

<!--<app-testimonials></app-testimonials>-->

<!-- <app-blog></app-blog> -->

<!-- <app-partner></app-partner> -->
