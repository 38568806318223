import { Component, OnInit } from '@angular/core';
import AOS from 'aos'

@Component({
    selector: 'app-facility-style-one',
    templateUrl: './facility-style-one.component.html',
    styleUrls: ['./facility-style-one.component.scss']
})
export class FacilityStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        AOS.init();
    }

    facilityContent = [
        {
            icon: 'bx bxs-check-circle',
            title: 'Qualità',
            paragraph: 'Sviluppiamo soluzioni di qualità, prestando particolare attenzione alla loro <b>funzionalità</b> e <b>durabilità nel tempo</b>'
        },
        {
            icon: 'bx bx-line-chart',
            title: 'Affidabilità',
            paragraph: 'Da oltre <b>15 anni</b> svolgiamo questo mestiere con passione e professionalità'
        },
        {
            icon: 'bx bxs-check-shield',
            title: 'Sicurezza',
            paragraph: '<b>Vivi in sicurezza</b> con i nostri prodotti, a norma di legge e revisionati dal nostro team'
        },
        {
            icon: 'bx bx-store',
            title: 'Showroom',
            paragraph: 'Vieni a trovarci per poter toccare con mano i nostri prodotti.'
        }
    ]
    facilityImage = [
        {
            img: 'assets/img/image00009-min.jpeg'
        }
    ]

}
