<section class="facility-area ptb-100 bg-f5f5f5">
    <div class="my-4 text-center">
        <span class="title-section">Recensioni</span>
    </div>
    <div class="review-container">
        <div class="row">
            <div class="col-12">
                <div class="card w-100 shadow-lg">
                    <div class="card-body text-center">
                        <h5 class="card-title text-uppercase font-weight-bold">Arturo</h5>
                        <p class="card-text text-secondary">Esperienza assolutamente positiva! Dopo diversi preventivi
                            abbiamo trovato Progettop con
                            una inserzione su facebook. Con prodotti veramente belli, sopralluogo e preventivo
                            velocissimi, decisione obbligata e immediata.... infissi stupendi e di qualità. Il personale
                            è
                            stato veloce, attento professionale e super disponibile alle nostre esigenze. Non potevamo
                            trovare di meglio siamo felici della scelta fatta. Grazie a tutti di cuore</p>
                        <div class="rating">
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="card w-100 shadow-lg">
                    <div class="card-body text-center">
                        <h5 class="card-title text-uppercase font-weight-bold">Renata</h5>
                        <p class="card-text text-secondary">I tempi sono stati rispettati il lavoro perfetto sono molto
                            soddisfatto
                            anche per la gentilezza degli installatori</p>
                        <div class="rating">
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star '></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="card w-100 shadow-lg">
                    <div class="card-body text-center">
                        <h5 class="card-title text-uppercase font-weight-bold">Giuseppe</h5>
                        <p class="card-text text-secondary">Lo scorso venerdì è terminata l’installazione di tutti i
                            serramenti. Sono
                            estremamente soddisfatta dell’operato di Antonio e il suo Team.
                            Precisione capillare dimostrata in tutte le fasi operative. Orgogliosa di
                            aver scelto Progettop. Grazie</p>
                        <div class="rating">
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                            <i class='bx bxs-star rated'></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- altre card -->
    </div>


</section>
