<div class="container">
    <div class="my-4 text-center">
        <span class="title-section">FAQ</span>
    </div>
    <div class="accordion">
        <div class="accordion-item">
            <button id="accordion-button-1" aria-expanded="false"><span class="accordion-title">Perché Scegliere Serramenti Su Misura?
</span><span class="icon" aria-hidden="true"></span></button>
            <div class="accordion-content">
                <p>Una soluzione su misura ti permette di
                    personalizzare le dimensioni e di avere un isolamento
                    ottimale dell’ambiente. Inoltre, grazie alle tante
                    colorazioni e finiture disponibili potrai integrare
                    perfettamente i serramenti ai tuoi ambienti.</p>
            </div>
        </div>
        <div class="accordion-item">
            <button id="accordion-button-2" aria-expanded="false"><span
                class="accordion-title">Quali sono le finestre migliori?</span><span class="icon" aria-hidden="true"></span>
            </button>
            <div class="accordion-content">
                <p>Non esistono serramenti e infissi migliori. Ognuno di
                    noi ha delle esigenze diverse che dipendono da casa
                    a casa. Ogni finestra viene valutata dai nostri
                    professionisti dopo un’attenta e rigorosa consulenza
                    tecnica ti consiglieranno la soluzione più adatta alle
                    tue esigenze. Le migliori finestre sono quelle scelte
                    per il tuo contesto!</p>
            </div>
        </div>
        <div class="accordion-item">
            <button id="accordion-button-3" aria-expanded="false"><span class="accordion-title">Quanto dura il ciclo di vita di un Serramento in PVC?
</span><span
                class="icon" aria-hidden="true"></span></button>
            <div class="accordion-content">
                <p>Almeno 30 anni ma non possiamo essere più precisi
                    perché la sua durata va considerata con molta
                    attenzione. Questo dato viene desunto dalle buone
                    condizioni in cui si trovano i primi infissi in pvc,
                    apparsi sul mercato circa una trentina di anni fa.</p>
            </div>
        </div>
        <div class="accordion-item">
            <button id="accordion-button-4" aria-expanded="false"><span class="accordion-title">Quanto Influisce Il Cambio Dei Serramenti Sul
Risparmio Energetico?</span><span
                class="icon" aria-hidden="true"></span></button>
            <div class="accordion-content">
                <p>Tantissimo. Le vecchie finestre con vetro singolo
                    disperdono 5 volte il calore di uno di nuova
                    generazione. Un risparmio energetico equivale a un
                    importante risparmio in bolletta.</p>
            </div>
        </div>
        <div class="accordion-item">
            <button id="accordion-button-5" aria-expanded="false"><span class="accordion-title">Quali sono le finestre migliori?</span><span
                class="icon" aria-hidden="true"></span></button>
            <div class="accordion-content">
                <p>Non esistono serramenti e infissi migliori. Ognuno di
                    noi ha delle esigenze diverse che dipendono da casa
                    a casa. Ogni finestra viene valutata dai nostri
                    professionisti dopo un’attenta e rigorosa consulenza
                    tecnica ti consiglieranno la soluzione più adatta alle
                    tue esigenze. Le migliori finestre sono quelle scelte
                    per il tuo contesto!</p>
            </div>
        </div>
    </div>
</div>
