import {Products} from './interfaces/furniture-interface';

export const listProducts: Array<Products> = [
    {
        id: 44,
        title: 'Mod_CLASSIC-01-LACCATO_BIANCO.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 45,
        title: 'Mod_CLASSIC-02-Decape-Base-Bianco-Poro-Grigio.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 46,
        title: 'Mod_CLASSIC-FM-01-Decape-Base-Bianco-Poro-Grigio.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 47,
        title: 'Mod_CLASSIC-FM-02-Decape-Base-Bianco-Poro-Grigio.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 48,
        title: 'Mod_CLASSIC-FM-03-Decape-Base-Bianco-Poro-Grigio-1.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 49,
        title: 'Mod_CLASSIC-FM-04-Decape-Base-Bianco-Poro-Grigio-1.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 50,
        title: 'Mod_GLASSY-01_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 51,
        title: 'Mod_GLASSY-02_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 52,
        title: 'Mod_GLASSY-03_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 53,
        title: 'Mod_GLASSY-04_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 54,
        title: 'Mod_GLASSY-05_Laccato-TORTORA-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 55,
        title: 'Mod_GLASSY-06_Laccato-TORTORA-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 56,
        title: 'Mod_GLASSY-07_Laccato-TORTORA-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 57,
        title: 'Mod_GLASSY-08_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 58,
        title: 'Mod_GLASSY-09_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 59,
        title: 'Mod_GLASSY-10_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 60,
        title: 'Mod_GLASSY-11_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 61,
        title: 'Mod_GLASSY-13_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 62,
        title: 'Mod_GLASSY-14_Laccato-TORTORA-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 63,
        title: 'Mod_GLASSY-15_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 64,
        title: 'Mod_GLASSY-16_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 65,
        title: 'Mod_GLASSY-17_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 66,
        title: 'Mod_GLASSY-18_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 67,
        title: 'Mod_GLASSY-19_Laccato-TORTORA.jpg',
        description: 'Una linea di porte svetrate con fermavetro per dare più luce ai tuoi spazi. I modelli della collezione Glassy si possono realizzare con qualsiasi tipo di finitura e vetro.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 68,
        title: 'Mod_INCISO-01_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 69,
        title: 'Mod_INCISO-02_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 70,
        title: 'Mod_INCISO-03_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 71,
        title: 'Mod_INCISO-04_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 72,
        title: 'Mod_INCISO-05_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 73,
        title: 'Mod_INCISO-07_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 74,
        title: 'Mod_INCISO-08_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 75,
        title: 'Mod_INCISO-10_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 76,
        title: 'Mod_INCISO-13_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 77,
        title: 'Mod_INCISO-18_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 78,
        title: 'Mod_INCISO-22_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 79,
        title: 'Mod_INCISO-27_Laccato.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Laccato',
        category: 0,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 80,
        title: 'Mod_GLASS-01-V_Laccato.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 81,
        title: 'Mod_GLASS-01-V_Laminato.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 82,
        title: 'Mod_GLASS-01-V_Legno.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 83,
        title: 'Mod_GLASS-01_Laccato.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 84,
        title: 'Mod_GLASS-01_Laminato.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 85,
        title: 'Mod_GLASS-01_Legno.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 86,
        title: 'Mod_GLASS-02_Laccato.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 87,
        title: 'Mod_GLASS-02_Laminato.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 88,
        title: 'Mod_GLASS-02_Legno.jpg',
        description: 'Che si tratti di lasciar trasparire la luce tra diversi ambienti, rifletterla ampliando la percezione dello spazio, o sfruttare le infinite possibilità artistiche di decori e stampa serigrafica, la porta in vetro offre tutte le soluzioni per un design esclusivo capace di rispettare la funzionalità e allo stesso tempo di infondere bellezza ad ogni passaggio.',
        type: 'Vetro',
        category: 3,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 89,
        title: 'Mod_CLASSIC-01_Frassino.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 90,
        title: 'Mod_CLASSIC-02_Frassino.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 91,
        title: 'Mod_CLASSIC-FM-01_Frassino.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 92,
        title: 'Mod_CLASSIC-FM-02_Frassino.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 93,
        title: 'Mod_CLASSIC-FM-03_Frassino.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 94,
        title: 'Mod_CLASSIC-FM-04_Frassino.jpg',
        description: 'Linee semplici, versatilità e tinte originali sono i tratti principali della collezione Classic. Dettagli delicati ed essenziali capaci di soddisfare qualsiasi esigenza stilistica di arredo.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 95,
        title: 'Mod_EPIC-03_Rovere.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 96,
        title: 'Mod_EPIC-04_Rovere.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 97,
        title: 'Mod_EPIC-07D_Rovere-1.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 98,
        title: 'Mod_EPIC-152A_Rovere-1.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 99,
        title: 'Mod_EPIC-153D_Rovere-1.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 100,
        title: 'Mod_EPIC-154D_Rovere.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 101,
        title: 'Mod_EPIC-155A_Rovere.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 102,
        title: 'Mod_EPIC-30-2A_Rovere.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 103,
        title: 'Mod_EPIC-79A_Rovere-1.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 104,
        title: 'Mod_EPIC-85D_Rovere-1.jpg',
        description: 'La decorazione scolpita sul pannello è una lavorazione manuale che caratterizza la linea Epic. Un mix di originalità ed eleganza, adatto ad ambienti sia classici che moderni, che propone modelli eccentrici ma mai eccessivi.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 105,
        title: 'Mod_GLASSY-01_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 106,
        title: 'Mod_GLASSY-02_Frassino.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 107,
        title: 'Mod_GLASSY-03_Frassino.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 108,
        title: 'Mod_GLASSY-04_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 109,
        title: 'Mod_GLASSY-05_Frassino.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 110,
        title: 'Mod_GLASSY-06_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 111,
        title: 'Mod_GLASSY-07_Frassino-2.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 112,
        title: 'Mod_GLASSY-08_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 113,
        title: 'Mod_GLASSY-13_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 114,
        title: 'Mod_GLASSY-14_Frassino-2.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 115,
        title: 'Mod_GLASSY-15V_Frassino-4.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 116,
        title: 'Mod_GLASSY-15_Frassino.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 117,
        title: 'Mod_GLASSY-16_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 118,
        title: 'Mod_GLASSY-17_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 119,
        title: 'Mod_GLASSY-18_Frassino.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 120,
        title: 'Mod_GLASSY-19_Frassino.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 121,
        title: 'Mod_GLASSY-20_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 122,
        title: 'Mod_GLASSY-21_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 123,
        title: 'Mod_GLASSY-22_Frassino-1.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 124,
        title: 'Mod_GLASSY-3V-OB_Frassino-2.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 125,
        title: 'Mod_GLASSY-3V_Frassino-2.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 126,
        title: 'Mod_GLASSY-4V_Frassino-2.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 127,
        title: 'Mod_GLASSY-5V_Frassino-3.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 128,
        title: 'Mod_GLASSY-6V_Frassino-5.jpg',
        description: 'Una linea di porte svetrate con fermavetro, per dare più luce ai tuoi spazi. Tutti i modelli della collezione Glassy si possono realizzare in qualsiasi essenza e vetro.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 129,
        title: 'Mod_INCISO-01_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 130,
        title: 'Mod_INCISO-02_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 131,
        title: 'Mod_INCISO-03_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 132,
        title: 'Mod_INCISO-04_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 133,
        title: 'Mod_INCISO-05_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 134,
        title: 'Mod_INCISO-07_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 135,
        title: 'Mod_INCISO-08_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 136,
        title: 'Mod_INCISO-10_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 137,
        title: 'Mod_INCISO-13_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 138,
        title: 'Mod_INCISO-18_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 139,
        title: 'Mod_INCISO-22_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 140,
        title: 'Mod_INCISO-38_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 141,
        title: 'Mod_INCISO-39_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 142,
        title: 'Mod_INCISO-40_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 143,
        title: 'Mod_INCISO-41_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 144,
        title: 'Mod_INCISO-42_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 145,
        title: 'Mod_INCISO-43_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 146,
        title: 'Mod_INCISO-44_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 147,
        title: 'Mod_INCISO-45_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 148,
        title: 'Mod_INCISO-46_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 149,
        title: 'Mod_INCISO-47_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 150,
        title: 'Mod_INCISO-48_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 151,
        title: 'Mod_INCISO-54_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 152,
        title: 'Mod_INCISO-55_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 153,
        title: 'Mod_INCISO-56_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 154,
        title: 'Mod_INCISO-57_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 155,
        title: 'Mod_INCISO-59_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 156,
        title: 'Mod_INCISO-60_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 157,
        title: 'Mod_INCISO-61_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 158,
        title: 'Mod_INCISO-62_Frassino.jpg',
        description: 'Linee nette e dal carattere forte contraddistinguono i modelli della linea inciso, una collezione i cui pannelli vengono decorati con delle incisioni, anche personalizzate. Ideali per coloro che vogliono rendere gli ambienti originali e unici.',
        type: 'Legno',
        category: 2,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 159,
        title: 'Mod_MEETING-BASIC_03-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 160,
        title: 'Mod_MEETING-BASIC_03_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 161,
        title: 'Mod_MEETING-BASIC_04-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 162,
        title: 'Mod_MEETING-BASIC_04_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 163,
        title: 'Mod_MEETING-BASIC_06-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 164,
        title: 'Mod_MEETING-BASIC_06_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 165,
        title: 'Mod_MEETING-BASIC_09-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 166,
        title: 'Mod_MEETING-BASIC_09_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 167,
        title: 'Mod_MEETING-BASIC_11-V_PALISSANDRIO-AVORIO.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 168,
        title: 'Mod_MEETING-BASIC_11_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 169,
        title: 'Mod_MEETING-BASIC_15-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 170,
        title: 'Mod_MEETING-BASIC_15_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 171,
        title: 'Mod_MEETING-BASIC_24_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 172,
        title: 'Mod_MEETING-BASIC_26-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 173,
        title: 'Mod_MEETING-BASIC_26.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 174,
        title: 'Mod_MEETING-BASIC_29-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 175,
        title: 'Mod_MEETING-BASIC_29_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 176,
        title: 'Mod_MEETING-BASIC_38-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 177,
        title: 'Mod_MEETING-BASIC_38_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 178,
        title: 'Mod_MEETING-BASIC_44-V_PALISSANDRO-AVORIO-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 179,
        title: 'Mod_MEETING-BASIC_44_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 180,
        title: 'Mod_MEETING-BASIC_45_Palissandro-Avorio.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 181,
        title: 'Mod_MEETING-PLUS_01-V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 182,
        title: 'Mod_MEETING-PLUS_01_Beck-Avana-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 183,
        title: 'Mod_MEETING-PLUS_02-V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 184,
        title: 'Mod_MEETING-PLUS_02_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 185,
        title: 'Mod_MEETING-PLUS_05-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 186,
        title: 'Mod_MEETING-PLUS_05_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 187,
        title: 'Mod_MEETING-PLUS_07-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 188,
        title: 'Mod_MEETING-PLUS_07_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 189,
        title: 'Mod_MEETING-PLUS_08-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 190,
        title: 'Mod_MEETING-PLUS_08_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 191,
        title: 'Mod_MEETING-PLUS_10-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 192,
        title: 'Mod_MEETING-PLUS_10_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 193,
        title: 'Mod_MEETING-PLUS_12-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 194,
        title: 'Mod_MEETING-PLUS_12_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 195,
        title: 'Mod_MEETING-PLUS_13-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 196,
        title: 'Mod_MEETING-PLUS_13_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 197,
        title: 'Mod_MEETING-PLUS_14-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 198,
        title: 'Mod_MEETING-PLUS_14_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 199,
        title: 'Mod_MEETING-PLUS_16-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 200,
        title: 'Mod_MEETING-PLUS_16_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 201,
        title: 'Mod_MEETING-PLUS_17-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 202,
        title: 'Mod_MEETING-PLUS_17_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 203,
        title: 'Mod_MEETING-PLUS_18-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 204,
        title: 'Mod_MEETING-PLUS_18_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 205,
        title: 'Mod_MEETING-PLUS_19-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 206,
        title: 'Mod_MEETING-PLUS_19_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 207,
        title: 'Mod_MEETING-PLUS_20V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 208,
        title: 'Mod_MEETING-PLUS_20_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 209,
        title: 'Mod_MEETING-PLUS_21-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 210,
        title: 'Mod_MEETING-PLUS_21_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 211,
        title: 'Mod_MEETING-PLUS_22-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 212,
        title: 'Mod_MEETING-PLUS_22_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 213,
        title: 'Mod_MEETING-PLUS_23-02_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 214,
        title: 'Mod_MEETING-PLUS_23V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 215,
        title: 'Mod_MEETING-PLUS_24V_PALISSANDRO-AVORIO.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 216,
        title: 'Mod_MEETING-PLUS_25_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 217,
        title: 'Mod_MEETING-PLUS_27-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 218,
        title: 'Mod_MEETING-PLUS_27_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 219,
        title: 'Mod_MEETING-PLUS_28-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 220,
        title: 'Mod_MEETING-PLUS_28_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 221,
        title: 'Mod_MEETING-PLUS_30-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 222,
        title: 'Mod_MEETING-PLUS_30_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 223,
        title: 'Mod_MEETING-PLUS_31_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 224,
        title: 'Mod_MEETING-PLUS_32_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 225,
        title: 'Mod_MEETING-PLUS_33-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 226,
        title: 'Mod_MEETING-PLUS_33_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 227,
        title: 'Mod_MEETING-PLUS_34-V_BECK-AVANA-1.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 228,
        title: 'Mod_MEETING-PLUS_34_-BEck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 229,
        title: 'Mod_MEETING-PLUS_35-V_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 230,
        title: 'Mod_MEETING-PLUS_35_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 231,
        title: 'Mod_MEETING-PLUS_36-V_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 232,
        title: 'Mod_MEETING-PLUS_36_Beck-Avana.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 233,
        title: 'Mod_MEETING-PLUS_37-V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 234,
        title: 'Mod_MEETING-PLUS_37_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 235,
        title: 'Mod_MEETING-PLUS_39-V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 236,
        title: 'Mod_MEETING-PLUS_39_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 237,
        title: 'Mod_MEETING-PLUS_40-V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 238,
        title: 'Mod_MEETING-PLUS_40_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 239,
        title: 'Mod_MEETING-PLUS_41-V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 240,
        title: 'Mod_MEETING-PLUS_41_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 241,
        title: 'Mod_MEETING-PLUS_42-V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 242,
        title: 'Mod_MEETING-PLUS_42_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 243,
        title: 'Mod_MEETING-PLUS_43V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 244,
        title: 'Mod_MEETING-PLUS_43_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 245,
        title: 'Mod_MEETING-PLUS_46-V_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 246,
        title: 'Mod_MEETING-PLUS_46_BECK-AVANA.jpg',
        description: 'Un gioco di incastri, pannelli, montanti e traversi, compongono le porte della linea Meeting, tanti modelli per ogni gusto ed esigenza.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 247,
        title: 'Mod_METAL_DEBY-01.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 248,
        title: 'Mod_METAL_DEBY-02.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 249,
        title: 'Mod_METAL_DEBY-03.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 250,
        title: 'Mod_METAL_DEBY-04.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 251,
        title: 'Mod_METAL_DEBY-05.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 252,
        title: 'Mod_METAL_DEBY-06.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 253,
        title: 'Mod_METAL_DEBY-07.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 254,
        title: 'Mod_METAL_DEBY-08.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 255,
        title: 'Mod_METAL_DESY-01_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 256,
        title: 'Mod_METAL_DESY-02_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 257,
        title: 'Mod_METAL_DESY-03_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 258,
        title: 'Mod_METAL_DESY-04_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 259,
        title: 'Mod_METAL_DESY-05_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 260,
        title: 'Mod_METAL_DESY-06_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 261,
        title: 'Mod_METAL_DESY-07_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 262,
        title: 'Mod_METAL_DESY-08_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 263,
        title: 'Mod_METAL_DESY-09_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 264,
        title: 'Mod_METAL_DESY-10_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 265,
        title: 'Mod_METAL_DESY-11_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 266,
        title: 'Mod_METAL_DESY-12_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 267,
        title: 'Mod_METAL_DESY-13_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 268,
        title: 'Mod_METAL_DESY-14_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 269,
        title: 'Mod_METAL_DESY-15_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 270,
        title: 'Mod_METAL_DESY-16_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 271,
        title: 'Mod_METAL_DESY-17_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 272,
        title: 'Mod_METAL_DESY-18_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 273,
        title: 'Mod_METAL_DESY-19_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 274,
        title: 'Mod_METAL_DESY-20_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 275,
        title: 'Mod_METAL_DESY-21_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 276,
        title: 'Mod_METAL_DESY-22_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 277,
        title: 'Mod_METAL_DESY-24_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 278,
        title: 'Mod_METAL_DESY-25_BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 279,
        title: 'Mod_METAL_DESY-26BECK-AVANA.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 280,
        title: 'Mod_METAL_MERY-01.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 281,
        title: 'Mod_METAL_MERY-02.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 282,
        title: 'Mod_METAL_MERY-03.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 283,
        title: 'Mod_METAL_MERY-04.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 284,
        title: 'Mod_METAL_MERY-05.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 285,
        title: 'Mod_METAL_MERY-06.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 286,
        title: 'Mod_METAL_MERY-07.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 287,
        title: 'Mod_METAL_MERY-08.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 288,
        title: 'Mod_METAL_MERY-09.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 289,
        title: 'Mod_METAL_MERY-10.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 290,
        title: 'Mod_METAL_MERY-11.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 291,
        title: 'Mod_METAL_MERY-12.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 292,
        title: 'Mod_METAL_MERY-13.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 293,
        title: 'Mod_METAL_MERY-14.jpg',
        description: 'Porte con inserti metallici per un design ricercato, ma dalle linee essenziali.',
        type: 'Laminato',
        category: 1,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 10,
        title: 'pro_veka_artline_intro_08.png',
        description: 'Sistema da 82 mm con telaio fisso quasi totalmente isolabile per un’estetica elegante: dall’esterno la finestra sembrerà “a tutto vetro”. Sistema con elevato isolamento termico e ante a scomparsa. Valori di trasmittanza termica fino a Uw = 0,73 W/(m²K) ottenuti in abbinamento a tripli vetri altamente isolanti con Ug 0,5 W/(m²K). L’altezza della battuta del vetro di 28 mm riduce i ponti termici. Possibilità di vetraggio da 34 a 60 mm di spessore',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 11,
        title: 'pro_veka_drehladen_intro_31.png',
        description: 'Massima resistenza agli agenti atmosferici e all’invecchiamento, per un’eccellente qualità delle superfici anche in condizioni ambientali aggressive (ad es. zone marine e industriali). Struttura dei profili robusta e indeformabile. Rinforzi metallici estremamente resistenti e adeguati al sistema. Resistenza all’effrazione di qualità superiore. Superficie liscia e di facile manutenzione. Incidenza della luce regolabile a piacere grazie alle lamelle orientabili',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 12,
        title: 'pro_veka_ekosol_70_intro.png',
        description: 'Profili multicamera con una profondità di montaggio standard di 70 mm.. Bordi eleganti, leggermente arrotondati. Altezze visibili: da 131 a 146,5 mm. Uso generale di rinforzi metallici in conformità con le direttive VEKA in materia. Coefficiente di trasmittanza termica. Valore Uw (elemento finestra):fino a 1,8 W/(m²K) a seconda del tipo di vetratura. Valore Ud (elemento porta scorrevole):fino a 1,8 W/(m²K) a seconda del tipo di vetratura',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 13,
        title: 'pro_veka_ekosol_90_intro.png',
        description: 'Profili multicamera con una profondità di montaggio standard di 90 mm. Bordi eleganti, leggermente arrotondati. Altezze visibili: da 131 a 146,5 mm. Uso generale di rinforzi metallici in conformità con le direttive VEKA in materia. Coefficiente di trasmittanza termica. Valore Uw (elemento finestra): fino a 1,8 W/(m²K) a seconda del tipo di vetratura. Valore Ud (elemento porta scorrevole): fino a 1,8 W/(m²K) a seconda del tipo di vetratura',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 14,
        title: 'pro_veka_scuro_intro.png',
        description: 'estremamente funzionali e resistenti agli urti. rinforzati nella struttura interna da un’anima in acciaio zincato. offrono una valida protezione. contro i tentativi di effrazione. costituendo inoltre un’efficace barriera isolante contro le intemperie. il calore e la luce dei raggi solari',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 15,
        title: 'pro_veka_softline70_haustuer_intro_1_16.png',
        description: 'Sistema di portoncini con spessore di base di 70 mm. Stabilità ottimale grazie al rinforzo metallico di grandi dimensioni con accoppiamenti angolari integrati. Vetratura profonda per montare tutti i tipi di pannelli per portoncini in commercio e versatilità estetica. Guarnizioni doppie a pavimento per evitare giri d’aria e ridurre le perdite di calore. La soglia a pavimento termicamente separata garantisce un isolamento perfetto anche in basso. Design classico con spigoli elegantemente arrotondati. Il fissaggio invisibile e il profilato coprente interscambiabile hanno una funzione estetica. Il profilo parapioggia in alluminio fa deviare la pioggia battente',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 16,
        title: 'pro_veka_softline82_haustuer_intro_1_16.png',
        description: 'Sistema di portoncini robusti con spessore di base di 82 mm per le massime esigenze. Geometria delle ante termicamente ottimizzata per trasmittanza termica fino a Uf = 1,1 W/(m²K). Soglia a pavimento integrata con separazione termica (senza barriere). Gli spessori maggiori delle pareti esterne di 3,5 mm sottolineano l’elevata esigenza di qualità della struttura della porta e garantiscono una qualità perfetta. Ante speciali per i portoncini con pannelli coprenti le ante per un design attuale. Per pannelli coprenti da 3 a 4 mm di spessore; disponibili anche in abbinamento con pannelli in alluminio. Design classico con spigoli elegantemente arrotondati',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 17,
        title: 'pro_veka_vekaslide70_intro_16.png',
        description: 'Trasmittanza termicaUf = 1,6 W/(m²K). Le vetrature di spessore fino a 42 mm consentono l’utilizzo di lastre ad alto isolamento termico e quindi valori fino a Ud = 0,8 W/(m²K) usando tripla lastra ad alta termocoibentazione. Soglia a pavimento duotermicamente separata con cuneo isolante integrato per la massima efficienza energetica e assenza di barriere grazie alla bassa altezza della struttura (50 mm). Design classico con spigoli elegantemente arrotondati',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 18,
        title: 'pro_veka_vekaslide82_intro_10.png',
        description: 'Trasmittanza termicaUf = 1,4 W/(m²K). Le vetrature di spessore fino a 52 mm consentono l’utilizzo di lastre ad alto isolamento termico e quindi valori fino aUd = 0,8 W/(m²K) usando tripla lastra ad alta termocoibentazione. Soglia a pavimento duotermicamente separata con cuneo isolante integrato per la massima efficienza energetica e assenza di barriere grazie alla bassa altezza della struttura (50 mm). Design classico con spigoli elegantemente arrotondati',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 19,
        title: 'pro_veka_vekavariant_intro_22.png',
        description: 'Un unico sistema per tutte le esigenze poste a cassonetti moderni per avvolgibili. Isolamento eccezionale. Usb = 0,76 W/(m²K), fattore die termico: fRsi = 0,70. Disponibile in tre diverse dimensioni: 175, 210 e 235 mm di altezza. Pannello di ispezione posteriore o inferiore. Più sicurezza grazie al dispositivo antisollevamento coestruso. Isolamento innovativo in testa. Fissaggio laterale senza viti di cassonetto e telaio con linguette laterali di acciaio',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 27,
        title: 'SL70AD_300_400_72DPI_INTRO_21.png',
        description: 'Profilo a 5 camere con 70 mm di spessore di base.Trasmittanza termica fino a Uw = 0,83 W/(m²K) usando tripla lastra ad alta termocoibentazione con Ug = 0,5 W/(m²K). Linee classiche con spigoli leggermente arrotondati, larghezze a vista molto sottili e guarnizioni grigie. Facile da pulire grazie alle superfici omogenee e i listelli in vetro a filo. Statica ideale per un funzionamento duraturo e il mantenimento del valore nel tempo',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 29,
        title: 'SL70AD_600_450_72DPI_GALERIE_21.png',
        description: 'Profilo a 5 camere con 70 mm di spessore di base.Trasmittanza termica fino a Uw = 0,83 W/(m²K) usando tripla lastra ad alta termocoibentazione con Ug = 0,5 W/(m²K). Linee classiche con spigoli leggermente arrotondati, larghezze a vista molto sottili e guarnizioni grigie. Facile da pulire grazie alle superfici omogenee e i listelli in vetro a filo. Statica ideale per un funzionamento duraturo e il mantenimento del valore nel tempo',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 32,
        title: 'SL76AD_450_600_72DPI_ZOOM_02.png',
        description: 'Sistema multicamere con profondità di 76 mm per un eccellente isolamento termico. Valori di isolamento fino a Uw = 0,81 W/(m²K) con impiego di triplo vetro altamente isolante con Ug = 0,5 W/(m²K). Due livelli di tenuta come protezione da rumore, freddo, umidità e correnti d’aria. Spessore dei vetri di 18-48 mm. Impiego universale per ristrutturazioni e nuove costruzioni. Spessore parete conforme alle alte normative di qualità (DIN EN 12608, classe A)',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 34,
        title: 'SL76MD_450_600_72DPI_ZOOM_03.png',
        description: 'Sistema multicamere con profondità di 76 mm per un eccellente isolamento termico. Valori di isolamento fino a Uw = 0,76 W/(m²K) con impiego di triplo vetro altamente isolante con Ug = 0,5 W/(m²K). Tre livelli di tenuta come protezione da rumore, freddo, umidità e correnti d’aria. Spessore dei vetri di 18-48 mm. Impiego universale per ristrutturazioni e nuove costruzioni. Spessore parete conforme alle alte normative di qualità (DIN EN 12608, classe A)',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 35,
        title: 'SL82AD_600_450_72DPI_GALERIE_17.png',
        description: 'Sistema di 82 mm con geometria innovativa multicamere per un eccellente isolamento termico. Trasmittanza termica fino a Uw = 0,74 W/(m²K) usando tripla lastra ad alta termocoibentazione con Ug = 0,5 W/(m²K). La profondità di 28 mm dell’alloggiamento del vetro riduce i ponti termici. Uso universale sia in nuove costruzioni che per ristrutturazioni.Spessore della parete con il massimo standard di qualità conforme a RAL “Classe A” (DIN EN 12608).I tre livelli di guarnizioni proteggono da rumori, freddo, umidità e giri d’aria',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 36,
        title: 'SL82MD_300_400_72DPI_INTRO_17.png',
        description: 'Sistema di 82 mm con geometria innovativa multicamere per un eccellente isolamento termico. Trasmittanza termica fino a Uw = 0,74 W/(m²K) usando tripla lastra ad alta termocoibentazione con Ug = 0,5 W/(m²K). La profondità di 28 mm dell’alloggiamento del vetro riduce i ponti termici. Uso universale sia in nuove costruzioni che per ristrutturazioni.Spessore della parete con il massimo standard di qualità conforme a RAL “Classe A” (DIN EN 12608). I tre livelli di guarnizioni proteggono da rumori, freddo, umidità e giri d’aria',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 41,
        title: 'SWL70AD_400_650_72DPI_ZOOM_13.png',
        description: 'Profilo a 5 camere con 70 mm di spessore di base. Trasmittanza termica fino a Uw = 0,73 W/(m²K) usando tripla lastra ad alta termocoibentazione con Ug 0,4 W/(m²K). Design attraente, arrotondato per elevate esigenze, linee slanciate e proporzioni equilibrate. L’armonia estetica complessiva è completata da guarnizioni discrete di color grigio nelle ante e nei telai',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    },
    {
        id: 42,
        title: 'SWL70AD_600_450_72DPI_GALERIE_13.png',
        description: 'Profilo a 5 camere con 70 mm di spessore di base. Trasmittanza termica fino a Uw = 0,73 W/(m²K) usando tripla lastra ad alta termocoibentazione con Ug 0,4 W/(m²K). Design attraente, arrotondato per elevate esigenze, linee slanciate e proporzioni equilibrate. L’armonia estetica complessiva è completata da guarnizioni discrete di color grigio nelle ante e nei telai',
        type: 'Infissi',
        category: 4,
        color: [],
        outOfStock: false,
        detailsLink: ''
    }
];
