import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RequestService {

  host = '';

  constructor(private http: HttpClient) {
  }

  postRequest(path: string, operation: string, body?: any, bodyEncoded?: any, token?: string): Observable<any> {
    path = this.getBodyEncoded(this.encodeUri(path), bodyEncoded);
    return this.http.post(this.getHostUrl() + path, body).pipe(tap((res) => {
    }, () => {
    }, () => {
    }), catchError(this.handleError<Observable<object>>(' ERROR ' + operation, path)));
  }


  getRequest(path: string, operation?: string, bodyEncoded?: any, token?: string): Observable<any> {
    path = this.getBodyEncoded(this.encodeUri(path), bodyEncoded);
    return this.http.get(this.getHostUrl() + path).pipe(tap(_ => {


    }, (err) => {
      console.log(err);
    }, () => {
    }), catchError(this.handleError<Observable<object>>(' ERROR ' + operation, path)));
  }

  getBodyEncoded(path: string, bodyEncoded: { [s: string]: unknown; } | ArrayLike<unknown>) {
    let index = 0;
    if (bodyEncoded) {
      Object.entries(bodyEncoded).forEach((value) => {
        if (value[1]) {
          path = path + ((index === 0) ? '?' : '&') + value[0] + '=' + encodeURIComponent(String(value[1]));
          index += 1;
        }
      });
    }
    return path;
  }

  getHostUrl() {
    return this.host;
    // if (!this.host) {
    //   const url = window.location.protocol + '//' + window.location.hostname + '/vbiss-api';
    //   return url;
    // }

    // CONFIGURARE NGINX ADEGUATAMENTE PER POTERSI INTERFACCIARE CON IL SERVER API CORRETTO

  }


  private handleError<T>(operation = 'operation', url = this.getHostUrl(), result?: T, blob?: boolean) {

    return (error: any): Observable<T> => {

      // TODO: better job of transforming error for user consumption
      // ${error.message}
      let errorMessage = 'Generic error';
      if (error !== undefined) {
        if (errorMessage && errorMessage.includes(this.getHostUrl()) || errorMessage.includes(url)) {
          errorMessage = errorMessage.toString().replace(url, ' server ').replace(this.getHostUrl(), 'server');
        }
        // this.managePopup('Error in ' + operation, errorMessage, 'error');
        //  this.logError(`${operation} handleError - failed: ${errorMessage}`);
      }
      // Let the app keep running by returning an empty result.
      return throwError(errorMessage);
    };
  }

  encodeUri(uri: string) {
    return encodeURI(uri);
  }
}