import { Component, OnInit } from '@angular/core';
import AOS from 'aos'

@Component({
    selector: 'app-homeone-categories',
    templateUrl: './homeone-categories.component.html',
    styleUrls: ['./homeone-categories.component.scss']
})
export class HomeoneCategoriesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        AOS.init();
    }

    singleCategoriesBigBox = [
        {
            img: 'assets/img/box3.jpg', // 495x630
            title: 'Titolo 3',
            buttonText: 'Shop Now',
            link: 'shop-full-width-1'
        }
    ]
    singleCategoriesBox = [
        {
            img: 'assets/img/box1.jpg', // 690 300
            title: 'Titolo 1',
            buttonText: 'Shop Now',
            link: 'shop-full-width-1'
        },
        {
            img: 'assets/img/box2.jpg',
            title: 'Titolo 2',
            buttonText: 'Shop Now',
            link: 'shop-full-width-1'
        }
    ]

}
