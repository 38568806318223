import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/service/request-service.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    bodyForm: formBody;
    loading: any = false;
    msg: any;
    policy = false;

    constructor(private requestService: RequestService) { }

    ngOnInit(): void {

    }

    pageTitle = [
        {
            bgImage: 'assets/img/construction.jpg',
            title: 'Contact Us'
        }
    ]

    submit(form: NgForm) {
        // @ts-ignore
        form._template = 'box'
        // @ts-ignore
        form._subject = 'Hai ricevuto una nuova richiesta di contatto da: ' + form.name;
        this.loading = true;
        this.requestService.postRequest('https://formsubmit.co/ajax/52729ec392384da13900ff6cc50b66ee', '', form).subscribe((response) => {
        }, () => {
            this.loading = false;
            // @ts-ignore
            this.msg = {result: false, text: 'Errore durante l\'invio della mail, riprova più tardi o contatta l\'amministratore di sistema'};
        }, () => {
            this.msg = {result: true, text: 'Messaggio inviato correttamente, verrai ricontattato al più presto!'};
            this.loading = false;
        });
    }


}
interface formBody {
    name: string,
    email: string,
    telephNumber: number,
    text: string,
    policy: boolean;

}
