<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<!--<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">-->
<!--    <h1>{{Content.title}}</h1>-->
<!--    <ul>-->
<!--        <li><a routerLink="/">Home</a></li>-->
<!--        <li>{{Content.title}}</li>-->
<!--    </ul>-->
<!--</div>-->
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area pt-100">
    <div class="container">
        <div class="contact-inner-area mt-4">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="contact-form">
                        <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required">Il nome è un campo obbligatorio</div>
                                            <div *ngIf="name.errors.minlength">Il nome deve essere di minimo {{ name.errors.minlength.requiredLength }} caratteri.</div>
                                            <div *ngIf="name.errors.maxlength">Il nome deve essere di massimo 50 caratteri.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">

                                        <input required ngModel name="email" type="email" #email="ngModel" class="form-control" id="email" placeholder="E-mail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">L'E-mail è un campo obbligatorio</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input required ngModel name="number" type="tel" #number="ngModel" class="form-control" id="number" placeholder="Cellulare" pattern="[0-9].{9,}">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Il numero di cellulare è un campo obbligatorio</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control" placeholder="Descrivi brevemente il tipo di lavoro che vorresti realizzare..."></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Il messaggio è un campo obbligatorio.</div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div>
                                        <input required (input)="policy = $event.target.checked" type="checkbox" class="form-check-input" id="privacyCheckbox">
                                        <label class="form-check-label ml-2" for="privacyCheckbox"> Dichiaro di aver letto la <a data-toggle="modal" data-bs-toggle="modal" data-bs-target="#privacyModal" href="javascript:void(0)"> <strong><u>Privacy Policy & Cookie Policy</u></strong></a> </label>
                                    </div>
                                    <button type="submit"  class="default-btn" [disabled]="!contactForm.valid || !policy">
                                        <i *ngIf="loading === false" class='bx bx-paper-plane'></i>
                                        <div *ngIf="loading === true" class="spinner-border spinner-border-sm text-light" role="status">
                                        </div>
                                        Contattaci</button>
                                </div>
                                <div class="col-12">
                                    <div *ngIf="msg && msg.text" [ngClass]="msg.result === true ? 'alert-success' : 'alert-danger'" class="alert mt-3" role="alert">
                                        {{msg.text}}
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-map'></i>
                        </div>
                        <h3>Posizione</h3>
                        <p>Reggio Calabria, Cosenza, Catanzaro</p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-phone-call'></i>
                        </div>
                        <h3>Contatti</h3>
                        <p>Mobile: <a href="tel:+393347766425">(+39) 334 7766425</a></p>
                        <p>E-mail: <a href="mailto:antonio.canal@hotmail.i">antonio.canal@hotmail.i</a></p>
                    </div>
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class='bx bx-time-five'></i>
                        </div>
                        <h3>Orari </h3>
                        <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<div id="map">

    <div style="width: 100%;position: relative;"><iframe width="700" height="440" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Via%20fiume%2034%2C%20Reggio%20calabria+(PROGETTOP)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><div style="position: absolute;width: 80%;bottom: 10px;left: 0;right: 0;margin-left: auto;margin-right: auto;color: #000;text-align: center;"><small style="line-height: 1.8;font-size: 2px;background: #fff;">Powered by <a href="http://www.googlemapsgenerator.com/da/">googlemapsgen (da)</a> & <a href="https://axelsonsspa.se/presentkort/">presentkort massage stockholm</a></small></div><style>#gmap_canvas img{max-width:none!important;background:none!important}</style></div><br />
</div>
<!-- End Map Area -->


<!-- modal -->
<div class="modal fade" id="privacyModal" tabindex="-1" role="dialog" aria-labelledby="privacyModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="privacyModalLabel">Policy</h5>
            </div>
            <div class="modal-body overflow-auto" style="max-height: 400px">
                La presente informativa è redatta per informarti dei principi in
                materia di riservatezza che regolano l’uso da parte di PROGETTOP
                dei dati forniti dai clienti, compresi i dati raccolti attraverso questo
                sito internet. Si descrivono le modalità di gestione del sito in
                riferimento al trattamento dei dati personali degli utenti che lo
                consultano.
                Si tratta di un’informativa che è resa ai sensi del GDPR General
                Data Protection Regulation – Regolamento UE 2016/679 a coloro
                che interagiscono con i servizi Web di PROGETTOP, accessibili per
                via telematica a partire dall’indirizzo: https://www.proget-top.it.
                L’informativa è resa solo per il presente sito internet e non anche
                per altri siti Web eventualmente consultati dall’utente tramite link
                presenti su questo sito.
                TITOLARE E RESPONSABILE DEL TRATTAMENTO
                A seguito della consultazione di questo sito possono essere trattati
                dati relativi a persone identificate o identificabili. Il titolare del
                trattamento dei dati è Progettop S.R.L. con sede in Via Fiume, –
                Reggio Calabria (RC), P.IVA IT03171300803
                Il nominativo del responsabile del trattamento è disponibile presso
                la predetta sede del titolare.
                SOGGETTI AUTORIZZATI AL TRATTAMENTO E LUOGO DI
                TRATTAMENTO DEI DATI
                I trattamenti connessi ai servizi Web di questo sito hanno luogo
                presso la predetta sede di Progettop e sono curati solo da personale
                interno incaricato del trattamento, oppure da eventuali incaricati di
                occasionali operazioni di manutenzione e gestione del sito. Nessun
                dato derivante dal servizio web viene comunicato o diffuso.
                DATI TRATTATI E FINALITÀ
                Dati di navigazione – I sistemi informatici e le procedure software
                preposte al funzionamento di questo sito Web acquisiscono, nel
                corso del loro normale esercizio, alcuni dati personali la cui
                trasmissione è implicita nell’uso dei protocolli di comunicazione di
                Internet.
                Si tratta di informazioni che non sono raccolte per essere associate
                a interessati identificati, ma che per loro stessa natura potrebbero,
                attraverso elaborazioni ed associazioni con dati detenuti da terzi,
                permettere di identificare gli utenti. In questa categoria di dati
                rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati
                dagli utenti che si connettono al sito, gli indirizzi in notazione URI
                (Uniform Resource Identifier) delle risorse richieste, l’orario della
                richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la
                dimensione del file ottenuto in risposta, il codice numerico indicante
                lo stato della risposta data dal server (buon fine, errore, ecc.) ed
                altri parametri relativi al sistema operativo e all’ambiente
                informatico dell’utente.
                Questi dati vengono utilizzati al solo fine di ricavare informazioni
                statistiche anonime sull’uso del sito e per controllarne il corretto
                funzionamento. I dati potrebbero essere utilizzati per l’accertamento
                di responsabilità in caso di ipotetici reati informatici ai danni del sito
                solo su richiesta degli organi di vigilanza preposti.
                Dati forniti volontariamente dall’utente – I dati personali forniti
                dall’utente tramite form sono raccolti e trattati:
                per lo svolgimento delle attività di relazione con il cliente e per
                rispondere alle richieste dello stesso;
                per per finalità amministrative e per l’adempimento di obblighi di
                legge (es. di natura contabile, fiscale);
                per l’invio periodico tramite e-mail di newsletter e materiale
                pubblicitario, previo specifico consenso;
                Nei casi espressamente indicati la base giuridica che legittima il
                trattamento è il consenso liberamente fornito dall’utente, in
                alternativa l’esecuzione di un contratto di cui l’interessato è parte.
                COOKIES – I cookies sono dati creati da un server che sono
                memorizzati in file di testo sull’hard disk del Suo computer e
                consentono il funzionamento del presente sito internet, l’uso di una
                specifica funzionalità esplicitamente richiesta dall’utente ovvero
                permettono di migliorare il funzionamento di questo sito, come i
                cookie che rendono la navigazione più veloce o che mostrano i
                contenuti di maggiore interesse per l’utente in funzione delle scelte
                precedenti. I cookies possono essere permanenti (c.d. cookies
                persistenti), ma possono anche avere una durata limitata (c.d.
                cookies di sessione). Questo sito può utilizzare sia cookies
                persistenti che di sessione. Quelli di sessione non vengono
                memorizzati in modo permanente sul Suo computer e svaniscono
                con la chiusura del browser. Quelli persistenti servono per
                personalizzare la navigazione in funzione dello strumento utilizzato
                dall’utente (computer, tablet, smartphone) così come i cookies di
                terze parti che servono per analizzare gli accessi del sito (es.
                Google Analytics) e per permettere agli utenti di condividere i
                contenuti del sito attraverso social network. Questi cookies,
                vengono memorizzati in modo permanente sul Suo computer e
                hanno una durata variabile.
                NATURA DEL CONFERIMENTO
                A parte quanto specificato per i dati di navigazione, l’utente è libero
                di fornire i dati personali riportati nei moduli di richiesta o
                comunque indicati in contatti per sollecitare l’invio di materiale
                informativo o di altre comunicazioni. Il loro mancato conferimento
                può comportare l’impossibilità di ottenere quanto richiesto in forma
                anche anonima.
                MODALITA’ DI TRATTAMENTO E TEMPO DI CONSERVAZIONE DEI
                DATI
                I dati personali sono trattati con strumenti automatizzati, informatici
                o mediante elaborazione manuale sempre nel rispetto della finalità
                per la quale i dati personali sono stati raccolti e in modo da
                garantire in ogni caso la sicurezza dei medesimi. I dati sono
                conservati per il tempo strettamente necessario a conseguire gli
                scopi per cui sono stati raccolti, nel rispetto delle normative vigenti
                e degli obblighi di legge.
                DIRITTI DELL’INTERESSATO
                I soggetti cui si riferiscono i dati personali hanno il diritto in
                qualunque momento di ottenere la conferma dell’esistenza o meno
                dei medesimi dati e di conoscerne il contenuto e l’origine,
                verificarne l’esattezza o chiederne l’integrazione o l’aggiornamento,
                oppure la rettifica. Hanno altresì il diritto di chiedere la
                cancellazione, la trasformazione in forma anonima o il blocco dei
                dati trattati in violazione di legge, nonché di opporsi in ogni caso,
                per motivi legittimi, al loro trattamento. Questa informativa sulla
                privacy può essere modificata periodicamente e l’uso delle
                informazioni raccolte è soggetto all’informativa sulla privacy in
                effetto al momento dell’uso.
                Il presente documento costituisce l’informativa sulla Privacy di
                questo sito internet e sarà soggetto ad aggiornamenti
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
            </div>
        </div>
    </div>
</div>
