import {AfterViewInit, Component, OnInit} from '@angular/core';
import AOS from 'aos';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit, AfterViewInit {

    constructor() {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/banner-about.png',
            title: 'About Us'
        }
    ];

    aboutContent = [
        {
            subTitle: ``,
            title: `IL NOSTRO OBIETTIVO`,
            paragraph: `Il nostro scopo è contribuire a migliorare la qualità
della vita e il comfort abitativo di case, negozi,
uffici, bar, ristoranti e capannoni.


Per questo progettiamo soluzioni ad hoc per ogni
tipo di richiesta e selezioniamo con cura ogni
infisso, ricercando soluzioni estetiche e parametri
nuovi per il raggiungimento del migliore
isolamento termico e di un ottimo abbattimento
acustico. I nostri prodotti rigorosamente Made in
Italy hanno come peculiarità, la sicurezza e la
protezione con un’ottima qualità prezzo. La cura
per il design delle nostre numerose proposte è
l essenza per scegliere e decidere secondo il
proprio stile e personalità. Non perdiamo mai di
vista la misura del nostro impegno, che
rappresenta il nostro stile e la nostra azienda e che
garantisce un prodotto in grado di rispondere ad
ogni richiesta e di durare negli anni.`
        }
    ];
    aboutImage = [
        {
            img: 'assets/img/about1.jpg'
        },
        {
            img: 'assets/img/about2.jpg'
        }
    ];

    // tslint:disable-next-line:typedef
    ngAfterViewInit() {
        const counters = document.querySelectorAll('.counter');

        counters.forEach((counter: any) => {
            counter.innerText = '0';
            const updateCounter = () => {
                const target = +counter.getAttribute('data-target');
                const count = +counter.innerText;
                const increment = target / 200;
                if (count < target) {
                    counter.innerText = `${Math.ceil(count + increment)}`;
                    setTimeout(updateCounter, 1);
                } else { counter.innerText = target; }
            };
            updateCounter();
        });

    }

    ngOnInit(): void {
        AOS.init();
    }

}
