import {Component, OnInit, Sanitizer} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    constructor(public sanitize: DomSanitizer) { }

    ngOnInit(): void {
    }

    mainBannerItem = [
        {
            subTitle: '',
            title: 'Tu lo <span  id="orange-p">P</span>ensi, Noi lo <span class="orange-p">P</span>rogettiamo.',
            paragraph: 'Vuoi rinnovare la tua casa o hai in mente un nuovo progetto?',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'Richiedi un preventivo',
            buttonLink: 'contact',
            image: 'assets/img/image00001-min.jpeg'
        }
    ]
    bannerSocialLinks = [
        {
            icon: 'bx bxl-facebook',
            link: 'https://www.facebook.com/Progettop-104469365558927/'
        },
        // {
        //     icon: 'bx bxl-twitter',
        //     link: '#'
        // },
        // {
        //     icon: 'bx bxl-linkedin',
        //     link: '#'
        // },
        {
            icon: 'bx bxl-instagram',
            link: 'https://instagram.com/progettop_s.r.l'
        }
    ]

    homeSlidesOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		margin: 5,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
                items: 1
			},
			576: {
                items: 1
			},
			768: {
                items: 1
			},
			992: {
                items: 1
			},
			1200: {
                items: 1
			}
		}
    }

}
