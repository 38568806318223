import {Component, OnInit} from '@angular/core';
import {listProducts} from 'src/assets/furnitureList';
import {Products} from 'src/assets/interfaces/furniture-interface'


@Component({
    selector: 'app-shop-left-sidebar-page-one',
    templateUrl: './shop-left-sidebar-page-one.component.html',
    styleUrls: ['./shop-left-sidebar-page-one.component.scss']
})
export class ShopLeftSidebarPageOneComponent implements OnInit {
    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };


    // options = [
    //     {
    //         name: "Default",
    //     },
    //     {
    //         name: "Popularity",
    //     },
    //     {
    //         name: "Latest",
    //     },
    //     {
    //         name: "Price: low to high",
    //     },
    //     {
    //         name: "Price: high to low",
    //     }
    // ];

    productModel: Products;

    pageTitle = [
        {
            bgImage: 'assets/img/banner-catalogo.jpg',
            title: 'Catalogo'
        }
    ]

    singleProductsBox = [];

    // For Pagination
    shopLeftSidebar: number = 1;

    isLoading = false;


    // For Filter

    filterDoor: number;
    selectedCategory: number


    constructor() {
    }


    ngOnInit(): void {
        // this.resetOption = [this.options[0]];
        this.singleProductsBox = listProducts;


    }


    //filter door start

    filter(category: number) {
        this.shopLeftSidebar = 1;
        this.loadPage();
        this.selectedCategory = category;
        this.singleProductsBox = listProducts.filter((x) => x.category === category);
    }


    closeFilter() {
        this.shopLeftSidebar = 1;

        this.loadPage();
        this.singleProductsBox = listProducts;
        this.selectedCategory = undefined;
    }

    countProducts(category: number) {
        return listProducts.filter((x) => x.category === category).length;
    }

    // filter door end


    selectItems(product: Products) {
        this.productModel = product;
    }


    loadPage = () => {
        this.isLoading = true;
        setTimeout(() => {
            this.isLoading = false;
        }, 600);
    }

    backToTop() {
        const backProduct = document.getElementById('backProduct');
        backProduct.scrollIntoView({behavior: 'smooth'});
    }


    searchChange($event) {
        console.log($event);
    }

    reset() {
        this.resetOption = [];
    }


    getCategory(category: number): string {
        if (category === 0) {
            return 'Laccato';
        } else if (category === 1) {
            return 'Laminato';
        } else if (category === 2) {
            return 'Legno';
        } else if (category === 3) {
            return 'Vetro';
        } else if (category === 4) {
            return 'Infissi';
        } else {
            return '';
        }
    }

    getColor(selectedColor: number, colors: Array<string>): string {
        if (!selectedColor) {
            return colors[0];
        }
        return colors[selectedColor];
    }
}
