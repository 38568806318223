import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-homeone-faq',
  templateUrl: './homeone-faq.component.html',
  styleUrls: ['./homeone-faq.component.scss']
})
export class HomeoneFaqComponent implements OnInit, AfterViewInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    // tslint:disable-next-line:typedef
    ngAfterViewInit() {
        const items = document.querySelectorAll('.accordion button');

        // tslint:disable-next-line:typedef
        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded');

            for (let i = 0; i < items.length; i++) {
                items[i].setAttribute('aria-expanded', 'false');
            }

            if (itemToggle == 'false') {
                this.setAttribute('aria-expanded', 'true');
            }
        }

        items.forEach(item => item.addEventListener('click', toggleAccordion));

    }

}
