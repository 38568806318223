<!-- Start Facility Area -->
<section class="facility-area ptb-100 bg-f5f5f5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="facility-content">
                    <div class="item" *ngFor="let Content of facilityContent;">
                        <i class='{{Content.icon}}'></i>
                        <h3>{{Content.title}}</h3>
                        <p [innerHTML]="Content.paragraph"></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="facility-image text-center" *ngFor="let Image of facilityImage;">
                    <img  height="700" width="430" [src]="Image.img" alt="image" data-aos="fade-up-left">
                </div>
            </div>
        </div>
<hr class="my-5">
        <div class="row">
            <div class="col-6 col-md-4">
                <div class="card" routerLink="catalogo">
                    <img src="assets/img/gallery/INFISSI.jpg" alt="Immagine della card">
                    <div class="card-text">
                        <h3>INFISSI</h3>
                    </div>
                    <div class="card-overlay">
                        <p><strong >VAI AL CATALOGO</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="card" routerLink="catalogo">
                    <img src="assets/img/gallery/PORTE.jpg" alt="Immagine della card">
                    <div class="card-text">
                        <h3>PORTE</h3>
                    </div>
                    <div class="card-overlay">
                        <p><strong >VAI AL CATALOGO</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="card" routerLink="catalogo">
                    <img src="assets/img/gallery/PORTE%20BLINDATE.jpg" alt="Immagine della card">
                    <div class="card-text">
                        <h3>PORTE BLINDATE</h3>
                    </div>
                    <div class="card-overlay">
                        <p><strong >VAI AL CATALOGO</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="card" routerLink="catalogo">
                    <img src="assets/img/gallery/TAPPARELLE.jpg" alt="Immagine della card">
                    <div class="card-text">
                        <h3>TAPPARELLE</h3>
                    </div>
                    <div class="card-overlay">
                        <p><strong >VAI AL CATALOGO</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="card" routerLink="catalogo">
                    <img src="assets/img/gallery/PERSIANE.jpg" alt="Immagine della card">
                    <div class="card-text">
                        <h3>PERSIANE</h3>
                    </div>
                    <div class="card-overlay">
                        <p><strong >VAI AL CATALOGO</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="card" routerLink="catalogo">
                    <img src="assets/img/gallery/ZANZARIERE.jpg" alt="Immagine della card">
                    <div class="card-text">
                        <h3>ZANZARIERE</h3>
                    </div>
                    <div class="card-overlay">
                        <p><strong >VAI AL CATALOGO</strong></p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Facility Area -->
