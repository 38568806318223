<!--3 row where for each row we have a card that is border rounded with title and description and a BIG NUMBER on the right of the row-->
<div class="my-4 text-center">
    <span class="title-section">3 BUONI MOTIVI PER SCEGLIERE I NOSTRI SERRAMENTI</span>
</div>
<div class="reasons-container">
    <div class="row">
        <div class="col-md-9">
            <div class="card reason-card">
                <div class="card-body">
                    <h5 class="card-title font-weight-bold">ISOLAMENTO TERMICO E ACUSTICO</h5>
                    <p class="card-text">Un buon isolamento contribuisce a ridurre notevolmente i costi
                        energetici e il comfort abitativo. Tutti i nostri serramenti, sono in grado
                        di garantire un ottima resistenza meccanica e un’ottima tenuta
                        ermetica. I distanziali a elevato isolamento termico migliorano le
                        prestazioni termoisolanti del serramento, ottimizzandolo termicamente.</p>
                </div>
            </div>
        </div>
        <div class="col-md-3" style="align-self: center">
            <h1 class="number-right">1</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3" style="align-self: center">
            <h1 class="number-left">2</h1>
        </div>
        <div class="col-md-9">
            <div class="card reason-card">
                <div class="card-body">
                    <h5 class="card-title font-weight-bold">RISPARMIO ENERGETICO</h5>
                    <p class="card-text">Grazie all’isolamento termico si risparmia notevolmente dimezzando il
                        costo del riscaldamento. In estate ti consente di mantenere il fresco,
                        prodotto dai climatizzatori più a lungo, facendo lavorare al minimo gli
                        stessi e consentendo un notevole risparmio: energetico e in bolletta!</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9">
            <div class="card reason-card">
                <div class="card-body">
                    <h5 class="card-title font-weight-bold">SICUREZZA</h5>
                    <p class="card-text">A casa vogliamo tutti sentirci sicuri. I nostri serramenti sono dotati di
                        blocco anti intrusione, maniglie anti apertura dall esterno. I portoncini o
                        nelle porte finestre sono dotati di lamina anti intrusione e anti torsione
                        inserita su tutto il perimetro del telaio e delle ante per rendere il
                        serramento più sicuro possibile.</p>
                </div>
            </div>
        </div>
        <div class="col-md-3" style="align-self: center">
            <h1 class="number-right">3</h1>
        </div>
    </div>
</div>
