<app-navbar-style-three></app-navbar-style-three>

<div id="loadingPage" *ngIf="isLoading"></div>


<!-- Start Page Title Area -->

<!-- End Page Title Area -->

<!-- Start Products Area -->
<section class="products-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area left-widget-area">
                    <!-- <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div> -->

                    <!-- <div class="widget widget_categories">
                        <h3 class="widget-title">Porte</h3>

                        <ul>
                            <li><a routerLink="/shop-left-sidebar-1">Accessories</a><span class="post_count">(5)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Men</a><span class="post_count">(7)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Women</a><span class="post_count">(3)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Sofa</a><span class="post_count">(2)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Uncategorized</a><span class="post_count">(8)</span></li>
                        </ul>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Infissi</h3>

                        <ul>
                            <li><a routerLink="/shop-left-sidebar-1">Accessories</a><span class="post_count">(5)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Men</a><span class="post_count">(7)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Women</a><span class="post_count">(3)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Sofa</a><span class="post_count">(2)</span></li>
                            <li><a routerLink="/shop-left-sidebar-1">Uncategorized</a><span class="post_count">(8)</span></li>
                        </ul>
                    </div>

                    <div class="widget widget_colors">
                        <h3 class="widget-title">Filter By Color</h3>

                        <ul>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="gray">Gray</span>
                                    <span>Gray</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="green">Green</span>
                                    <span>Green</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="red">Red</span>
                                    <span>Red</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="black">Black</span>
                                    <span>Black</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">
                                    <span class="brown">Brown</span>
                                    <span>Brown</span>
                                </a>
                            </li>
                        </ul>
                    </div> -->
                    <div class="accordion accordion-flush" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <h3 class="widget-title">Porte</h3>
                            </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body widget widget_categories">
                                <ul class="hoverAccordion">
                                    <li (click)="filter(2)" [ngClass]="selectedCategory === 2 ? 'activeFilrter' : '' "><button *ngIf="selectedCategory === 2" class="closeFilter" (click)="closeFilter();$event.preventDefault();$event.stopImmediatePropagation()"><i class='bx bx-x'></i></button><a >Legno</a><span class="post_count">({{this.countProducts(2)}})</span></li>
                                    <li (click)="filter(0)" [ngClass]="selectedCategory === 0 ? 'activeFilrter' : '' "><button *ngIf="selectedCategory === 0" class="closeFilter" (click)="closeFilter();$event.preventDefault();$event.stopImmediatePropagation()"><i class='bx bx-x'></i></button><a >Laccato</a><span class="post_count">({{this.countProducts(0)}})</span></li>
                                    <li (click)="filter(1)" [ngClass]="selectedCategory === 1 ? 'activeFilrter' : '' "><button *ngIf="selectedCategory === 1" class="closeFilter" (click)="closeFilter();$event.preventDefault();$event.stopImmediatePropagation()"><i class='bx bx-x'></i></button><a >Laminato</a><span class="post_count">({{this.countProducts(1)}})</span></li>
                                    <li (click)="filter(3)" [ngClass]="selectedCategory === 3 ? 'activeFilrter' : '' "><button *ngIf="selectedCategory === 3" class="closeFilter" (click)="closeFilter();$event.preventDefault();$event.stopImmediatePropagation()"><i class='bx bx-x'></i></button><a >Vetro</a><span class="post_count">({{this.countProducts(3)}})</span></li>
                                </ul>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <h3 class="widget-title">Infissi</h3>
                            </button>
                          </h2>
                          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body widget widget_categories">
                                <ul class="hoverAccordion">
                                    <li (click)="filter(4)" [ngClass]="selectedCategory === 4 ? 'activeFilrter' : '' "><button *ngIf="selectedCategory === 4" class="closeFilter" (click)="closeFilter();$event.preventDefault();$event.stopImmediatePropagation()"><i class='bx bx-x'></i></button><a>All</a><span class="post_count">({{this.countProducts(4)}})</span></li>
                                </ul>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <h3 class="widget-title">Filter By Color</h3>
                            </button>
                          </h2>
                          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body widget widget_colors">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <span class="gray">Gray</span>
                                            <span>Gray</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <span class="green">Green</span>
                                            <span>Green</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <span class="red">Red</span>
                                            <span>Red</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <span class="black">Black</span>
                                            <span>Black</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <span class="brown">Brown</span>
                                            <span>Brown</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                          </div>
                        </div> -->
                      </div>

                    <!-- <div class="widget widget_price_filter">
                        <h3 class="widget-title">Filter by Price</h3>

                        <div class="collection_filter_by_price">
                            <input class="js-range-of-price" type="text" data-min="0" data-max="1055" name="filter_by_price" data-step="10">
                        </div>
                    </div> -->


                </aside>
            </div>

            <div class="col-lg-8 col-md-12" id="backProduct">

                <!-- <div class="fiwan-grid-sorting row align-items-center">
                    <div class="col-lg-6 col-md-6 result-count">
                        <div class="d-flex align-items-center">
                            <p>We found <span class="count">09</span> products available for you</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 ordering">
                        <div class="select-box">
                            <label>Sort By:</label>
                            <ngx-select-dropdown (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown>
                        </div>
                    </div>
                </div> -->
                <div class="row">

                    <div class="col-lg-12 col-sm-12 col-md-12">

                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6 " *ngFor="let content of singleProductsBox  | paginate: { itemsPerPage: 8, currentPage: shopLeftSidebar }">

                        <div class="single-products-box">
                            <div class="products-image">
                                <!-- <a routerLink="/{{Content.detailsLink}}" class="d-block"> -->
                                    <img style="height: 300px" src="assets/img/{{content.type}}/{{content.title}}" alt="image">
                                <!-- </a> -->
                                <ul class="products-button">

                                    <!-- <li><a href="#" data-bs-toggle="modal" data-bs-target="#productsQuickView"><i class='bx bx-show-alt'></i></a></li> -->
                                    <!-- <li><a href="assets/img/{{content.type}}/{{content.title}}" class="popup-btn"><i class='bx bx-scan'></i></a></li> -->
                                    <!-- <li><a routerLink="/{{Content.detailsLink}}"><i class='bx bx-link'></i></a></li> -->
                                </ul>
                                <a (click)="selectItems(content)" data-bs-toggle="modal" data-bs-target="#productsQuickView" class="add-to-cart-btn"><i class='bx bx-show-alt'></i>Visualizza</a>
                            </div>
                            <!-- <div class="products-content">
                                <h3><a routerLink="/{{content.detailsLink}}">{{content.title}}</a></h3>
                                <div class="price">
                                    <span class="new-price">{{Content.newPrice}}</span>
                                    <span class="old-price" *ngIf="Content.oldPrice">{{Content.oldPrice}}</span>
                                </div>
                            </div> -->
                            <!-- <span class="on-sale" *ngIf="Content.sale">{{Content.sale}}</span> -->
                            <!-- <div class="out-of-stock" *ngIf="Content.outOfStock">{{Content.outOfStock}}</div> -->
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls (pageChange)="shopLeftSidebar = $event" (click)="loadPage()" (click)="backToTop()" ></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products Area -->

<!-- Start QuickView Modal Area -->
<div class="modal fade productsQuickView" id="productsQuickView" tabindex="-1" role="dialog" aria-hidden="true" >
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" *ngIf = "productModel">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class='bx bx-x'></i></span>
            </button>
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="products-image" style="text-align: center">
                        <img style="height: 400px" src="assets/img/{{productModel.type}}/{{productModel.title}}" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="products-content">
                        <h3>{{getCategory(productModel.category)}}</h3>
<!--                        <ul class="products-info">-->
<!--                            <li><span>Categoria:  </span> <a href="javascript:void(0)">{{getCategory(productModel.category)}}</a></li>-->
<!--                        </ul>-->
                        <p>{{productModel.description}}</p>
                        <div class="products-add-to-cart">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End QuickView Modal Area -->
