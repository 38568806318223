<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div style="margin-top:120px">
    <div class="row" style="text-align: center; text-align: -webkit-center">


        <div class="col-6 col-md-3">
            <div class="card">
                <div class="card-header text-center">
                    <h5>Progetti</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-center">
                        <div class="counter" data-target="500"></div>
                        <div class="plus" style="align-self: center; font-size: 20px">+</div>
                    </div>
                </div>
            </div>


        </div>
        <div class="col-6 col-md-3">
            <div class="card">
                <div class="card-header text-center">
                    <h5>Serramenti</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-center">
                        <div class="counter" data-target="800"></div>
                        <div class="plus" style="align-self: center; font-size: 20px">+</div>

                    </div>
                </div>
            </div>



        </div>
        <div class="col-6 col-md-3">
            <div class="card">
                <div class="card-header text-center">
                    <h5>Zanzariere</h5>

                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-center">
                        <div class="counter" data-target="1000"></div>
                        <div class="plus" style="align-self: center; font-size: 20px">+</div>

                    </div>
                </div>
            </div>

        </div>
        <div class="col-6 col-md-3">
            <div class="card">
                <div class="card-header text-center">
                    <h5>Clienti</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-center">
                        <div class="counter" data-target="700"></div>
                        <div class="plus" style="align-self: center; font-size: 20px">+</div>

                    </div>
                </div>
            </div>



        </div>
    </div>
</div>

<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
        <div class="about-content" *ngFor="let Content of aboutContent;">
            <span class="sub-title">{{Content.subTitle}}</span>
            <h2>{{Content.title}}</h2>
            <p [innerHTML]="Content.paragraph"></p>

            <div class="content" *ngFor="let Text of Content.content">
                <h3 [innerHTML]="Text.title"></h3>
                <p [innerHTML]="Text.paragraph"></p>
            </div>
        </div>





        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let Image of aboutImage;">
                <div class="about-image text-center">
                    <img height="800" width="765" [src]="Image.img" alt="image" data-aos="zoom-in">
                </div>
            </div>
        </div>
    </div>
</section>


<!-- End About Area -->

<!--<app-why-choose-us></app-why-choose-us>-->

<!--<app-video></app-video>-->

<!--<app-testimonials></app-testimonials>-->





